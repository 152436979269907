.notification-container {
  max-height: 500px;
  overflow-y: auto;
}

.notification-cell {
  width: 500px;
  max-width: 500px;
  display: grid;
  grid-template-columns: 424px 44px;
  padding: 6px 16px;
  overflow: hidden;
}

.notification-cell:hover {
  background-color: #ccc4;
}

.notification-cell-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #eee;
  border-radius: 2px;
}

.notification-cell-content * {
  border-radius: 2px;
}

.notification-info {
  display: flex;
  background-color: #ddd;
}

.notification-info div {
  flex: auto;
  margin: 0 5px;
  color: #888;
  font-size: 0.75em;
}

.notification-info-from {
  /* width: max-content; */
  text-align: left;
}

.notification-info-time {
  /* width: max-content; */
  text-align: right;
}

.notification-cell-content-text {
  box-sizing: border-box;
  padding: 5px;
  height: max-content;
}

.notification-cell-reply-container {
  overflow: hidden;
  height: 0px;
  transition: height 1s;
}

.notification-cell-reply-container form {
  width: 100%;
  height: 100%;
}

.notification-cell-reply {
  box-sizing: border-box;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  box-shadow: #bbb 2px 2px 5px inset;
  resize: none;
  border-radius: 5px;
}

.open-reply {
  height: 100px;
}

.close-reply {
  height: 0px;
}

.notification-cell-action {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.reply-button-container {
  display: flex;
  z-index: 10;
  overflow: hidden;
}

.reply {
  z-index: 5;
  height: 44px;
  transition: transform 1s, height 1s, width 1s, opacity 5s;
}

.rotate-appear {
  height: 44px;
}

.rotate-disappear {
  height: 0px;
}

.notification-refresh-button:active {
  animation: spin 0.25s ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
